<template>
  <div>
    <h2>{{ $t('views.orderreturns.detail.title') }}</h2>
    <loader v-if="is.loading"/>
    <template v-else>
      <div>
          <h3>{{ $t('views.orderreturns.detail.currentstate') }}: <b-badge>{{ $t(`views.orderreturns.detail.returnstate.${entity.orderReturnState.toLowerCase()}`) }}</b-badge></h3>
          <div class="alert alert-info">
            <p>{{ $t('views.orderreturns.detail.changestate') }}</p>

            <div v-if="nextPossibleStates.length" >
              <b-button class="mr-4" v-for="nextState in nextPossibleStates" :key="nextState.nextState" :variant="nextState.variant" @click.prevent="changeState(nextState.nextState)">{{ $t(`views.orderreturns.detail.returnstate.${nextState.nextState.toLowerCase()}`) }}</b-button>
              <b-modal v-model="showDiscardedDialog" @ok="changeStateToDiscarded" :title="$t('views.orderreturns.detail.discardedreasontitle')">
                <p>{{$t("views.orderreturns.detail.discardedreasontext")}}</p>
                <b-input-group>
                  <b-form-textarea v-model="discardedMessage" :state="$v.discardedMessage.$dirty ? !$v.discardedMessage.$error : null"></b-form-textarea>
                  <b-form-invalid-feedback>{{ $t("components.controls.feedbacks.invalid.required") }}</b-form-invalid-feedback>
                </b-input-group>
              </b-modal>
            </div>
            <div class="mt-5" v-if="entity.orderReturnState === 'Finished'">
              <b-button @click.prevent="downloadCreditNote">{{ $t("views.orderreturns.detail.downloadcreditnote") }}</b-button>
            </div>
          </div>
      </div>
      <entity-detail-table
        :entityKey="eParent.key"
        :entityId="eParent.id"

        @control:input="onInput"
        @control:change="onChange"
        @entity:save="onSave"
      />

      <div class="mb-4" v-for="(returnItem, index) in entity.products" :key="returnItem.product.id">
        <div class="row">
          <div class="col-12 col-md-8">
            <div class="d-flex align-items-center flex-column flex-md-row">
              <div class="image my-2 col-12 col-md-4 d-flex justify-content-center pe-4">
                <img class="order-return-image"  v-if="returnItem.product.image" :src="returnItem.product.image" @error="returnItem.product.image = ''" />
                <aspect-ratio v-if="!returnItem.product.image" ratio="4x3" class="is-fallback-image">
                  <b-icon icon="images"/>
                </aspect-ratio>
              </div>
              <div class="pe-3 w-100">
                <h6 class="mb-0">{{ returnItem.product.name }}</h6>
                <span class="text-primary small">{{ returnItem.product.unit }}</span>
                <br />
                <b-badge variant="warning">{{ $t(`views.orderreturns.detail.returnreason.${returnItem.reason.toLowerCase()}`) }}</b-badge>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-2 d-flex align-items-center my-2">
            <div>
              <div v-if="returnItem.price.isPromotionalPrice" class="small text-danger text-decoration-line-through">CHF {{ returnItem.price.singleOriginalPrice.toFixed(2) }}</div>
              <div class="text-primary">CHF {{ returnItem.price.singlePrice.toFixed(2) }}</div>
            </div>
          </div>
          <div class="col-6 col-md-2 d-flex align-items-center">
            <span class="fs-5 text-primary text-center fw-bold">{{ returnItem.quantity }}</span>
          </div>
        </div>
        <hr v-if="index < entity.products.length - 1" class="d-md-none" />
      </div>
    </template>
  </div>
</template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'
import EntityDetailTable from '@/components/entity/DetailTable'
import AspectRatio from '@/components/AspectRatio'
import Loader from '@/components/Loader'

export default {
  name: 'OrderReturns.Detail',
  mixins: [MixinEntityBase],
  components: {
    EntityDetailTable,
    Loader,
    AspectRatio
  },
  data () {
    return {
      showDiscardedDialog: false,
      discardedMessage: ''
    }
  },
  validations () {
    return {
      discardedMessage: { required: this.$root.validators.required }
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.eParent.key}/is`]
    },
    entity () {
      return this.$store.getters[`${this.eParent.key}/getEntityUnwrapped`](this.eParent.id) || {}
    },
    nextPossibleStates () {
      var nextStates = {
        open: [
          { nextState: 'Confirmed', variant: 'info' },
          { nextState: 'Discarded', variant: 'danger' }
        ],
        confirmed: [
          { nextState: 'Finished', variant: 'success' }
        ],
        discarded: [
          { nextState: 'Open', variant: 'warning' }
        ],
        finished: [
          { nextState: 'Open', variant: 'warning' }
        ]
      }

      return nextStates[this.entity.orderReturnState.toLowerCase()] ?? []
    }
  },
  methods: {
    onInput (controlValue) {
      /* do something with controlValue = { [propKey]: value } */
    },
    onChange (controlValue) {
      /* do something with controlValue = { [propKey]: value } */
    },
    onSave (entity) {
      this.$store.dispatch(`${this.eParent.key}/updateEntity`, entity)
    },
    async changeState (newState) {
      if (newState === 'Discarded') {
        this.showDiscardedDialog = true
      } else {
        await this.changeStateTo(newState)
      }
    },
    async changeStateToDiscarded (modalEvent) {
      this.$v.$touch()
      console.log(this.$v.$error)
      if (this.$v.$error) {
        modalEvent.preventDefault()
      } else {
        await this.changeStateTo('Discarded', this.discardedMessage)
      }
    },
    async changeStateTo (newState, discardedMessage) {
      this.showDiscardedDialog = false
      const result = await this.$store.dispatch(`${this.eParent.key}/setOrderReturnState`, {
        orderReturnId: this.entity.id,
        configuration: { state: newState, discardedMessage: discardedMessage }
      })

      if (result.success) {
        this.$store.dispatch(`${this.eParent.key}/getEntity`, this.eParent.id)
      }
    },
    async downloadCreditNote () {
      const file = await this.$store.dispatch(`${this.eParent.key}/getCreditNote`, {
        orderReturnId: this.entity.id
      })
      console.log(file)
      const url = window.URL.createObjectURL(file)
      const a = document.createElement('a')
      a.href = url
      a.download = `${this.$t('views.orderreturns.detail.creditnotefilename')}_${this.entity?.orderId}.pdf`
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
  }
}
</script>

<style lang="scss">
.order-return-image{
  width:200px;
  height:200px;
  object-fit:contain;
}
</style>
